import LABELS from "labels";
import CONSTANTS from "globalConstants";

const {
  KNOWLEDGEBUNDLE,
  TOPICCOLLECTION,
  ERROR_401,
  ERROR_IE,
  ERROR_ENTITLEMENT_API,
  ERROR_CASE_RESTRICTED_MDP,
  ERROR_INVALID_CASE_GUID,
  ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS,
    PRODUCTID,
    VIEW,
    CREATE_EDIT_DRAFT,
    PREVIEW_DOWNLOAD,
    PREVIEW_DOWNLOAD_R,
    EDIT_PUBLISHED_CONTENT,
    PUBLISH_CONTENT,
    SHOW_BUNDLE,
    CASE_PAGES,
    VIEW_CASE_DETAIL,
    VIEW_CLIENT_INFO,
    VIEW_BUDGET,
    EDIT,
    EDIT_TOPIC_TAGS,
    VIEW_CASE_MATERIALS,
    TAG_FRAMEWORK,
    PROFILES,
    EDIT_COLLEAGUE_PROFILE
  },
  TBDBS: {
    SEARCH_SUBJECT,
    SEARCH_FPA_IPA_INTERNAL,
    FPA,
    IPA,
    BCG_INTERNAL
  },
  CASE_TYPE_LIST: {
    FOLLOW_ON,
    CONVERTED,
    MIGRATED,
    RELATED,
  },
  SUGGESTIONS_TEXONOMY,
  SUGGESTIONS_AUTHORS,
  VALIDATION_KEY,
  PEOPLE_TITLE: {
    ASSOCIATE,
    CONSULTANT,
    PROJECT_LEADER,
    PRINCIPAL,
    PARTNER
  },
  AUTO_TAG_TYPE_TOOLTIP_CONFIG
} = CONSTANTS;

const CONFIG = {
  ALLOWED_FILE_EXTNS_METAGENAI: ['doc', 'docx', 'ppt', 'pptx', 'pdf'],
  META_GENAI_POLL_DELAY: 2000,
  META_GENAI_NEXT_POLL_DELAY: 2000,
  META_GENAI_UPDATE_DELAY: 5000,
  MAX_META_GENAI_POLL: 10,
  MAX_PROPOSAL_BIO_DELAY: 3000,
  MAX_PROPOSAL_BIO_POLL: 10,
  MAX_FACECARD_GEN_POLL: 30,
  MAX_FACECARD_GEN_DELAY: 2000,
  POLLING_ERROR_MESSAGE: "We are experiencing an issue in data generation, please try again after sometime",
  SERVER_URL: process.env.REACT_APP_FRONTEND_URL,
  ALTERNATE_SERVER_URL: process.env.REACT_APP_ALTERNATE_FRONTEND_URL,
  ENVIRONMENT: process.env.REACT_APP_ENV,
  ENVIRONMENT_MAIN: process.env.REACT_APP_ENV_MAIN,
  SKIPPED_THUMS_UPDOWN_ENABLED: process.env.REACT_APP_SKIP_CONDITION_THUMS_UPDOWN_ENABLED?.trim() === "true",
  DD: {
    AD: process.env.REACT_APP_DATADOG_RUM_APPID,
    CT: process.env.REACT_APP_DATADOG_RUM_CLIENTTOKEN
  },
  OKTA: {
    ISSUER: process.env.REACT_APP_OKTA_ISSUER,
    REDIRECT_URL:
      "/implicit/callback" /* Must be whitelisted in the Okta client app settings. */,
    CID: process.env.REACT_APP_OKTA_CLIENT_ID,
    AUTH_ATTEMPTED: "auth_attempted",
    CACHE_STORAGE: "okta-cache-storage",
    PSID: "psid",
    STK: "smartlogic_bearer_token",
    OKTA_PSID: "okta-psId",
  },
  ERROR_CONFIG: {
    [ERROR_401]: {
      HEADING: "401",
      MESSAGE: "WE’RE SORRY, YOU DO NOT HAVE SUFFICIENT PRIVILEGES.",
      HELP_TEXT: "Please contact IT service Center to request access.",
      BTN_LABEL: "REQUEST ACCESS",
      BTN_LINK_NEW: true,
      BTN_LINK: "https://bostonconsultinggroup.service-now.com/itconnect?id=sc_cat_item&sys_id=d7f82a12db21d810b7529ec2ca961918",
      WITH_LAYOUT: true,
    },
    [ERROR_IE]: {
      HEADING: "IE11",
      MESSAGE: "WE ARE SORRY, NAVIGATOR DOESN'T SUPPORT INTERNET EXPLORER 11",
      HELP_TEXT: "PLEASE USE CHROME OR MICROSOFT EDGE. BOTH CAN BE INSTALLED FROM YOUR COMPUTER'S SOFTWARE CENTER.",
    },
    [ERROR_ENTITLEMENT_API]: {
      // HEADING: "? ?",
      IMAGE_HEADING: "bulb",
      MESSAGE: "LOOKS LIKE WE ARE EXPERIENCING AN ISSUE!",
      HELP_TEXT: "Please try to refresh the page, if the error still persists then please raise an issue.",
      BTN_LABEL: "REFRESH",
      BTN2_LABEL: "RAISE AN ISSUE",
      WITH_LAYOUT: false,
    },
    [ERROR_CASE_RESTRICTED_MDP]: {
      IMAGE_HEADING: "bulb",
      HELP_TEXT: "Access restricted to MDPs associated with this case!",
    },
    [ERROR_INVALID_CASE_GUID]: {
      IMAGE_HEADING: "bulb",
      HELP_TEXT: "Incorrect URL. Please check the URL and try again.",
    }
  },
  UI_URL: {
    ALL: "*",
    HOME: "/",
    CONTRIBUTION: "/?tabselected=contributions",
    ERROR: (errorCode) => `/error/${errorCode}`,

    CREATION: `/creation`,
    CREATION_KP: `/creation?type=kp`,

    METADATA_KB: (step, kbId) => kbId ? `/kb/metadata/${step}/${kbId}` : step ? `/kb/metadata/${step}` : `/kb/metadata/:step?/:kbId?`,
    METADATA_KP: (step, kpId) => kpId ? `/kp/metadata/${step}/${kpId}/` : step ? `/kp/metadata/${step}` : `/kp/metadata/:step?/:kpid?`,
    METADATA_KP_VALIDATE: (step, kpId) => kpId ? `/kp/metadata/${step}/${kpId}/${VALIDATION_KEY}` : step ? `/kp/metadata/${step}` : `/kp/metadata/:step?/:kpid?`,

    KP_PREVIEW_DETAILS: (kpId) => `/kp/preview/${kpId}`,
    KP_CONSUMPTION: (kpId) => `/kp/${kpId}`,
    KB_PREVIEW: (kbId) => `/kb/preview/${kbId}`,
    KB_CONSUMPTION: (kbId) => `/kb/${kbId}`,
    KP_DOWNLOAD: (appName, type, kpId) => `/download/kp/${appName}/${type}/${kpId}`,

    CP_METADATA: (step, cid) => cid ? `/cases/edit/${step}/${cid}` : step ? `/cases/edit/${step}` : `/cases/edit/:step?/:cid?`,
    CP_CONSUMPTION_NEW: (cid) => `/cases/${cid}`,
    FACE_CARD_GENERATOR: `/facecardgenerator`,
    FACE_CARD_GENERATOR_V1: (isMainUrl, templateId) => !!isMainUrl ? `/facecardgenerator` : (!!templateId ? `/facecardgenerator/${templateId}` : `/facecardgenerator/:templateid?`)
  },
  INQUEUE_STATUS: `?tabselected=contributions&pill=inqueue`,
  THIRDPARTY_URL: {
    CP_LEGACY_KN: (projectId) => `${process.env.REACT_APP_BCGNAVIGATOR_DOMAIN}/Detail/project_detail.asp?Project_ID=${projectId}`,
    COVER_IMAGE: (img) => process.env.REACT_APP_IS_CDN_ENABLED?.trim() === "true" ? `${process.env.REACT_APP_MEDIA_URL}${img}` : `${process.env.REACT_APP_SITECORE_API_URL}${img}`,

    LEGACY_CONTRIBUTE_KNOWLEDGE: `http://bcgnavigator.bcg.com/Home/contribute_knowledge.asp`,
  },
  // urls that needs to be ignored from 401 reroute when all entitlements are false
  get KC_CONSUMPTION_URLS() {
    return [
      this.UI_URL.KP_PREVIEW_DETAILS(":kpId"),
      this.UI_URL.KP_CONSUMPTION(":kpId"),
      this.UI_URL.KB_PREVIEW(":kbId"),
      this.UI_URL.KB_CONSUMPTION(":kbId")
    ]
  },
  get KC_CONTRIBUTION_URLS() {
    return [
      this.UI_URL.CREATION,
      this.UI_URL.METADATA_KB(),
      this.UI_URL.METADATA_KP()
    ]
  },
  get KC_ALL_URLS() {
    return [
      ...this.KC_CONSUMPTION_URLS,
      ...this.KC_CONTRIBUTION_URLS
    ]
  },

  get KC_CONTRIBUTION_BUNDLE_URL() {
    return [
      this.UI_URL.METADATA_KB()
    ]
  },

  get CASE_CONSUMPTION_URLS() {
    return [
      this.UI_URL.CP_CONSUMPTION_NEW(":cid")
    ]
  },
  get CASE_CONTRIBUTION_URLS() {
    return [
      this.UI_URL.CP_METADATA()
    ]
  },
  get CASE_ALL_URLS() {
    return [
      ...this.CASE_CONTRIBUTION_URLS,
      ...this.CASE_CONSUMPTION_URLS
    ]
  },
  get FACE_CARD_URL() {
    return [
      this.UI_URL.FACE_CARD_GENERATOR_V1(false)
    ]
  },
  get FACE_CARD_ALL_URL() {
    return [
      ...this.FACE_CARD_URL
    ]
  },

  API_URL: {
    // Global - Permissions & Entitlements
    GET_ENTITLEMENT: () =>
      `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}entitlement/users/public/entitlements`,

    TASK_REGISTER: () =>
      `${process.env.REACT_APP_SMP_DOMAIN_PVT}/api/task_register`,
    TASK_STATUS: (taskId) =>
      `${process.env.REACT_APP_SMP_DOMAIN_PVT}/api/check_status?task_id=${taskId}`,
    GET_RECOMMENDED_TAGS: (taskId) =>
      `${process.env.REACT_APP_SMP_DOMAIN_PVT}/api/fetch_result?task_id=${taskId}`,
    POST_FEEDBACK_RECOMMENDED_TAGS: () =>
      `${process.env.REACT_APP_SMP_DOMAIN_PVT}/api/feedback`,

    // Knowledge Bundles Contribution
    GET_IMAGE: `${process.env.REACT_APP_SITECORE_API_URL}sitecore/api/ssc/aggregate/content/Items('{${process.env.REACT_APP_SITECORE_IMAGE_GUID}}')/children?sc_apikey=${process.env.REACT_APP_SITECORE_IMAGE_API_KEY}&$select=Name&$expand=FieldValues($select=Name,Small_Image_Path__Url)`,
    GET_IMAGE_XM: `${process.env.REACT_APP_SITECORE_XM_API_URL}api/KnowledgeBundle/topicimages`,
    GET_METADATA: (kbId) =>
      `${process.env.REACT_APP_SITECORE_XM_API_URL}api/KnowledgeBundle/${kbId}`,
    SAVE_METADATA: `${process.env.REACT_APP_SITECORE_XM_API_URL}api/KnowledgeBundle`,

    // Taxonomy
    GET_TAXONOMY: (taxonomyId) =>
      `${process.env.REACT_APP_SITECORE_API_URL}sitecore/api/ssc/aggregate/content/Items('{${taxonomyId}}')/children?sc_apikey=${process.env.REACT_APP_DOMAIN_API_KEY}&$select=Name,Id&$expand=Children($levels=max;$select=Name,Id)`,
    GET_TAXONOMY_XM: (taxonomyId) =>
      `${process.env.REACT_APP_SITECORE_XM_API_URL}api/language/{${taxonomyId}}`,
    GET_TAXONOMY_ODATA: (taxonomyId) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/taxonomy/{${taxonomyId}}`,
    GET_KNOWLEDGE_TAXONOMY: (taxonomyId) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/KnowledgeTaxonomy/{${taxonomyId}}`,
    GST: `${process.env.REACT_APP_SMARTLOGIC_API}/token`,
    GET_TYPESEARCH_DATA: (key, term) => `${process.env.REACT_APP_SMARTLOGIC_API}/svc/${process.env.REACT_APP_SMARTLOGIC_TYPESEARCH_API_KEY}/ses/?TBDB=${key}&service=prefix&term_prefix=${term}&template=service.json`,
    GET_SL_PARENT_TAGS: (key, ids) => `${process.env.REACT_APP_SMARTLOGIC_API}/svc/${process.env.REACT_APP_SMARTLOGIC_TYPESEARCH_API_KEY}/ses/?TBDB=${key}&service=browse&id=${ids}&template=service.json`,
    GET_EXPERT_TAGS: () => `${process.env.REACT_APP_SMARTLOGIC_API}/svc/${process.env.REACT_APP_SMARTLOGIC_TYPESEARCH_API_KEY}/ses/Subjects/name/Expert.json?FILTER=EQ_REL=Tag Type`,
    GET_SUBJECT_TAGS: () => `${process.env.REACT_APP_SMARTLOGIC_API}/svc/${process.env.REACT_APP_SMARTLOGIC_TYPESEARCH_API_KEY}/ses/IndustryPracticeArea0/name/Paaf.json?FILTER=EQ_REL%3DTag%20Type`,
    GET_OFFICES: `${process.env.REACT_APP_SITECORE_XM_API_URL}api/offices`,

    // Knowledge Page
    GET_PRESIGNED: (filename, guid, version) =>
      `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}kd/v1/s3presignedurl?fileName=${filename}&bucketName=${process.env.REACT_APP_UPLOAD_AWS_S3_BUCKET}&dir=${guid}/${version}`,
    GET_PRESIGNED_INLINE_API: (filename, guid, version) =>
      `/kd/v1/s3presignedurl/inline?fileName=${filename}&bucketName=${process.env.REACT_APP_UPLOAD_AWS_S3_BUCKET}&dir=${guid}/${version}`,
    SAVE_KP_DATA: `${process.env.REACT_APP_SITECORE_XM_API_URL}api/Knowledge/Page`,

    //Meta GenAI
    GET_META_GENAI: (requestId) =>
      `${process.env.REACT_APP_SMP_DOMAIN_PVT}/facecard-polling?appName=KPMetaGenAI&requestId=${requestId}`,

    //Material Status API
    GET_MATERIAL_STATUS: `${process.env.REACT_APP_SITECORE_XM_SOLAR_API_URL}api/materials/status`,

    //Get Contribute Knowledge Type API
    GET_CONTRIBUTE_KNOWLEDGE_TYPE: `${process.env.REACT_APP_SITECORE_XM_API_URL}api/knowledge/kptype`,

    // Versioning - Knowledge Objects (both KP and KB)
    EDIT_PUBLISHED_KP: (id) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/Knowledge/Page/${id}/edit`,
    EDIT_PUBLISHED_KB: (id) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/KnowledgeBundle/${id}/edit`,
    DELETE_KNOWLEDGE_ASSET: (id) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/Knowledge/delete/${id}`,
    UNPUBLISH_KNOWLEDGE_ASSET: (id) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/Knowledge/withdraw/${id}`,
    ARCHIVE_KNOWLEDGE_ASSET: (id) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/Knowledge/archive/${id}`,
    CLONE_KNOWLEDGE_ASSET: (id) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/Knowledge/page/clone/${id}`,

    //TAGS SUGGESTIONS
    GET_TAGS_SUGGESTIONS: () => `${process.env.REACT_APP_SITECORE_XM_SOLAR_API_URL}api/case/taxonomy/suggestions`,

    // Global - Profile/People API
    GET_PEOPLE: `${process.env.REACT_APP_API_PEOPLE}/domain/v2/people`,
    LINK_PROFILE_REDIRECT: (hrid) =>
      `${process.env.REACT_APP_PROFILE_LINK}/overview?hrid=${hrid}&source=cm`,

    //Preview Document
    GET_KP_IS_FILE_EXIST: (KPIds, appName, type) => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}download/document/exists?id=${KPIds}&appName=${appName}&type=${type}`,
    GET_KP_FILE: (guid, appName, type) => `${process.env.REACT_APP_SMP_DOMAIN_PVT}/downloadfile/${guid}?appName=${appName}&type=${type}`,

    GET_KP_FILE_LEGACY_NAVIGATOR: (legacyMaterialId, legacyAttachmentId) => `${process.env.REACT_APP_BCGNAVIGATOR_DOMAIN}/includes/download_redirect.asp?Material_ID=${legacyMaterialId}&Material_Attachment_ID=${legacyAttachmentId}`,

    //Clipping
    GET_CLIPPING_STATUS: (appName, id) => `${process.env.REACT_APP_SMP_DOMAIN_PVT}/clipfile/status/${id}?appName=${appName}`,
    CLIP_FILE: (appName) => `${process.env.REACT_APP_SMP_DOMAIN_PVT}/clipfile/clippingrequest?appName=${appName}`,

    GET_CASES_BT: ({ owner, ccstatus, days, withdrawn, caseType, allCount, pageSize, pageNumber, }) => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC_V1}case-service/case/bulk?owner=${owner}&ccstatus=${ccstatus}&days=${days}&withdrawn=${withdrawn}&caseType=${caseType}&allCount=${allCount}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    SAVE_CASE_BT: (guid) => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC_V1}case-service/case/${guid}`,
    GET_CASE_DURATION: () => `${process
      .env.REACT_APP_SMP_DOMAIN_PUBLIC_V1}case-service/case/duration`,

    // Get case owner list
    GET_CASE_KMSOWNERS: () => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC_V1}case-service/case/case-owner`,

    // Knowledge Pages detailed list
    GET_KP_LIST: `${process.env.REACT_APP_SITECORE_XM_SOLAR_API_URL}api/Knowledge/Pages`,
    // Case Knowledge Pages detailed list
    GET_CASE_KP_LIST: `${process.env.REACT_APP_SITECORE_API_URL_STG_PROD}api/Knowledge/Pages`,
    // To check if the logged in user is site admin/editor or not
    SITE_ADMIN_EDITOR: `${process.env.REACT_APP_SITECORE_XM_API_URL}api/Knowledge/User/InRole`,

    // Search Cases
    SEARCH_CASES: (caseNumber, doExactMatch, pageNumber, pageSize) => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}case-service/case/search?caseNumber=${caseNumber}&caseNumberMatch=${doExactMatch}&pageNumber=${pageNumber}&pageSize=${pageSize}`,

    //Docviz metadata
    DOCVIZ_METADATA: (id, isInPreviewMode) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/v2/Knowledge/Page/${id}/Docviz?isconsumptionview=${!isInPreviewMode}`,

    //multi guilds Docviz metadata
    DOCVIZ_METADATA_MULTI_GUIDS: (ids, isInPreviewMode) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/v3/Knowledge/Page/Docviz?isconsumptionview=${!isInPreviewMode}&ids=${ids}`,

    //Docviz metadata
    FRAMEWORK_POST_API: () => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/v1/knowledge/framework/slide`,

    // Docviz pooling api 
    DOCVIZ_POOLING: (id, isInPreviewMode) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/Knowledge/Page/${id}/docviz/status/?isconsumptionview=${!isInPreviewMode}`,

    //FACE CARD GENERATION
    FACE_CARD_GENERATION: () => `${process.env.REACT_APP_SMP_DOMAIN_PVT}/facecard/request`,

    //FACE CARD GENERATION V1
    FACE_CARD_GENERATOR_ACKNOWLEDGE: `${process.env.REACT_APP_SMP_DOMAIN_PVT}/facecard-ack`,
    FACE_CARD_GENERATOR_POLLING: `${process.env.REACT_APP_SMP_DOMAIN_PVT}/facecard-polling`,

    PROPOSAL_BIO_ACKNOWLEDGE: `${process.env.REACT_APP_SMP_DOMAIN_PVT}/facecard-ack`,
    PROPOSAL_BIO_POLLING: (requestId) => `${process.env.REACT_APP_SMP_DOMAIN_PVT}/facecard-polling?requestId=${requestId}&appName=proposalBio`,
    DOWNLOAD_ONLY_IMAGES_POLLING: (requestId) => `${process.env.REACT_APP_SMP_DOMAIN_PVT}/facecard-polling?appName=image&requestId=${requestId}`,

    // Polyhierarchy
    POLYHIERARCHY: () => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/v1/taxonomy/polyhierarchy`,

    // Get case history
    GET_CASE_HISTORY: (guid, rowCount) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/case/history/${guid}/?recordCount=${rowCount}`,



    // Search Knowledge Objects - Bundles and Pages
    SEARCH: {
      KC: {
        URL: (queryString) => `${process.env.REACT_APP_SITECORE_XM_SOLAR_API_URL}api/Knowledge/Search?${queryString}`,
        QUERY_PARAMS: {
          PAGE_NUMBER: 'pageNumber',
          PAGE_SIZE: 'pageSize',
          STATUS: 'status',
          ORIGINAL_CREATOR: 'user',
          LEGACY_NAV_ID: 'legacyNavId',
          ID: 'id',
          TEXT: 'text',
          TYPE: 'type'
        },
      },
      DEFAULTS: {
        PAGE_NUMBER: 1,
        PAGE_SIZE: 20,
        STATUS: ""
      }
    },
    // Miscellaneous
    GET_OFFICES_DATA: `${process.env.REACT_APP_SITECORE_XM_API_URL}api/knowledge/taxonomy/office`,
    WALKME_SCRIPT_URL: `${process.env.REACT_APP_WALKME_SCRIPT_URL}`,

    // Get KPs download count
    GET_BULK_DOWNLOADS_COUNTS: (kpIds) =>
      `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}download/documents/count?docIds=${kpIds}`,

    // Get Knowledge Bundles with a page
    GET_ASSOCIATED_BUNDLES: (kpId) => `${process.env.REACT_APP_SITECORE_XM_SOLAR_API_URL}api/KnowledgeBundle/GetBundles/${kpId}`,

    // Get Admin History data for  Knowledge Page
    GET_ADMIN_HISTORY_PATH: (kpId) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/knowledge/history/${kpId}`,

    // Get Pa Recommended  data 
    IS_PA_RECOMMENDED_PATH: (id) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/knowledge/parecommened/${id}`,

    // update Recommended PA data 
    UPDATE_RECOMMEND_PA_PATH: () => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/knowledge/parecommened`,

    // is in Role - PA Recommender
    IS_PA_RECOMMENDER_ROLE: () => `${process.env.REACT_APP_SITECORE_XM_SOLAR_API_URL}api/parecommened`,

    // Get Case metadata
    GET_CASE_METADATA: (guid) => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}case-service/case/${guid}`,

    // Save Case metadata
    SAVE_CASE_METADATA: () => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}case-service/case/save`,

    // Get case time and billing data
    GET_CASE_T_AND_B: (guid) => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}case-service/case/tb/${guid}`,

    // Get case Sam API data
    GET_CASE_SAM: (guid) => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}case-service/case/sam/${guid}`,

    // Get case FPA data
    GET_CASE_FPA_DETAILS: (guid) => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}case-service/case/fpa-overlay/${guid}`,

    // Get case CMP data
    GET_CASE_CMP: (guid) => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}case-service/case/cmp/${guid}`,

    // Get case Type data	
    GET_CASE_TYPES: (guid) => `${process.env.REACT_APP_SITECORE_XM_API_URL}api/case/type/${guid}`,

    // Get case Type data	
    GET_CASES_META_DETAILS: () => `${process.env.REACT_APP_SITECORE_XM_SOLAR_API_URL}api/casenumbers`,



    // Get cases open and close date	
    GET_CASE_TB_DATES: (guid) => `${process.env.REACT_APP_SMP_DOMAIN_PUBLIC}case-service/case/tb?guid=${guid}`,

    // Search PA recommend - Result/Activity Result/List
    GET_PA_RECOMMENDED_ACTIVITIES_LIST: (pageSize, pageNumber, days) => `${process.env.REACT_APP_SITECORE_XM_SOLAR_API_URL}api/knowledge/searchparecommend?pageSize=${pageSize}&pageNumber=${pageNumber}&days=${days}`,

    //UNIQUE TITLE CHECK
    GET_UNIQUE_TITLE_CONFIRMATION: () => `${process.env.REACT_APP_SITECORE_XM_SOLAR_API_URL}api/knowledge/page/validate/duplicatetitle`,
  },
  IS_RECOMMENDED_TAGGING_ON: true,
  IT_CONNECT_ACCESS: "https://bostonconsultinggroup.service-now.com/itconnect?id=sc_cat_item&sys_id=d7f82a12db21d810b7529ec2ca961918",
  ACTIVITIES__PAGE_SIZE: 20,
  ACTIVITIES__DEFAULt_DAYS: 7,
  IS_WALKME_ENABLED: process.env.REACT_APP_IS_WALKME_ENABLE?.trim() === "true",
  IS_ARCHIVE_VISIBLE: process.env.REACT_APP_IS_ARCHIVE_VISIBLE?.trim() === "true",
  IS_KPC_NEW_LAYOUT: process.env.REACT_APP_IS_KPC_NEW_LAYOUT?.trim() === "true",
  ENRICH_API_SMP_DOMAIN: `${process.env.REACT_APP_SMARTSEARCH_API_DOMAIN}`,
  KNOWLEDGE_PAGE_TYPE: 'KnowledgePage',
  KP_TYPE_BCG_INTERNAL: { id: "339643d6-c8dc-4d65-a2fa-ed6a56e64e79" },
  BUNDLE_TYPE: {
    [KNOWLEDGEBUNDLE]: `${process.env.REACT_APP_BUNDLETYPE_KB}`,
    [TOPICCOLLECTION]: `${process.env.REACT_APP_BUNDLETYPE_TC}`
  },
  EMBEDDED_VIDEO_URL_TYPE: ["bcove.video", "players.brightcove", "widen.net"],
  DAYS_FILTER_OPTIONS: [
    { key: 7, text: "Last 7 days", value: 7 },
    { key: 15, text: "Last 15 days", value: 15 },
    { key: 30, text: "Last 1 month", value: 30 },
    { key: 91, text: "Last 3 months", value: 91 },
    { key: 182, text: "Last 6 months", value: 182 },
    { key: 365, text: "Last 1 year", value: 365 },
  ],
  DOCVIZ_SCROLL_VIEW_EXTNS: ['ppt', 'pptx', 'pdf', 'doc', 'docx'],
  IS_FRAMEWORK_ENABLED: process.env.REACT_APP_IS_FRAMEWORK_ENABLED?.trim() === "true",
  IS_OLD_POLY_CONFIG: process.env.REACT_APP_IS_POLY_ENABLED?.trim() === "true",
  IS_CONTRIBUTE_TAB_ENABLED: process.env.REACT_APP_IS_CONTRIBUTE_KNOWLEDGE_TAB_ENABLED?.trim() === "true",
  POLY_HIERARCHICAL: [
    [
      { id: 'f7c063b3-e271-4130-ba40-54d4837aed2c', type: FPA },
      { id: '7fb35b48-257e-48e0-8991-7d5d678e3a23', type: FPA },
    ],
    [
      { id: '1db52330-f2db-45c4-93c0-85b376e594d9', type: FPA },
      { id: '33dbebf1-a9cc-4ad1-acd7-e829569d426a', type: FPA }
    ],
    [
      { id: 'b029ec4c-9bfc-432b-b0c7-807e577be6de', type: FPA },
      { id: 'b1b4b264-15e3-4f1d-af94-e5a62cde8126', type: FPA }
    ],
    [
      { id: '2307d865-a507-4d32-b684-d550f46761e0', type: FPA },
      { id: '5b9972bf-f202-4c33-bc75-75ad10f80299', type: FPA }
    ],
    [
      { id: '157c0d07-6a06-4cd7-9b39-8f2b621e31f4', type: FPA },
      { id: '933fe0db-c7b2-4cf3-9a85-38bb148a905f', type: FPA }
    ],
    [
      { id: 'e313dc31-16a1-417e-8c3f-dfcce429b98f', type: FPA },
      { id: '6d0059ae-a1c1-4503-b0e7-321287796980', type: FPA }
    ],
    [
      { id: 'fdabb807-c7c4-4ead-a499-d048007e5b17', type: FPA },
      { id: '7e4c03fe-e8b1-4521-8ea0-22893836e554', type: FPA }
    ],
    [
      { id: '9d49ed74-9291-4063-af11-f8ff02357f6d', type: IPA },
      { id: 'bc15e37b-78b1-4395-854c-ef31ff21f30e', type: IPA }
    ],
    [
      { id: 'c31ce2c8-5cdd-48df-94ba-325343561989', type: IPA },
      { id: 'c9c6267e-4e90-4447-ae38-b33b13f99245', type: IPA }
    ],
    [
      { id: 'e27ec256-12ba-46d5-a59e-a785a0105ac9', type: IPA },
      { id: 'fc6583b4-28cb-4ccf-a1d2-f676a4022d3b', type: IPA }
    ],
    [
      { id: '9bbe6868-ec9e-4050-b890-608ca1b897ef', type: IPA },
      { id: 'b84425c0-b202-4e27-a9ed-cf4a39ce1d2d', type: IPA }
    ],
    [
      { id: '14662cb3-007e-4c18-afa8-9314687595d4', type: IPA },
      { id: '00f35563-8de6-411e-96b8-038dc68974ad', type: IPA }
    ],
    [
      { id: '6ee68216-fdf8-48b8-a511-0bf8db0fa6f8', type: IPA },
      { id: '5a855c83-b6dd-455c-aa61-ab6aa69c7d85', type: IPA }
    ],
    [
      { id: '110d70a3-0292-4ae1-a61d-625dab4ade5c', type: IPA },
      { id: '254068c9-3a83-47cf-8041-314c7289f78c', type: IPA }
    ],
    [
      { id: '86392e59-c7b9-4229-9402-0689341229b4', type: IPA },
      { id: '265c954e-0884-49ec-a28b-34ea48e4fab2', type: IPA }
    ],
    [
      { id: '052781a1-5546-44ac-b9db-1d5fd4481bfe', type: IPA },
      { id: '742c1d6a-e9af-468a-99d4-aa0cd29351ad', type: IPA }
    ],
    [
      { id: '8508a157-54df-41b0-aeba-8e180820853f', type: IPA },
      { id: 'b19419bc-c8da-49ef-abe5-260dc998dd2b', type: IPA }
    ]
  ],


  TAXONOMY_IDS: {
    IPA: () => `${process.env.REACT_APP_TAXONOMY_IPA}`,
    FPA: () => `${process.env.REACT_APP_TAXONOMY_FPA}`,
    BCG_INTERNAL: () => `${process.env.REACT_APP_TAXONOMY_BCG_INTERNAL}`,
    SUBJECT: () => `${process.env.REACT_APP_TAXONOMY_SUBJECT}`,
    TOPICPAGE_IPA: () => `${process.env.REACT_APP_TAXONOMY_TOPICPAGE_IPA}`,
    TOPICPAGE_FPA: () => `${process.env.REACT_APP_TAXONOMY_TOPICPAGE_FPA}`,
    REGIONS_COUNTRIES: () => `${process.env.REACT_APP_TAXONOMY_REGIONSCOUNTRIES}`,
    GLOBAL_REGION: "fb57a427-7b3f-44c1-938a-4d55c52f500e",
    GLOBAL_INTERNAL_OFFICE: "fab65ff9-cfc0-4401-8dc5-197fc9e608f0",
    IGNOREGUIDS_BST: [
      "19cc6dd8-e933-4c5d-9898-594fc77498b9", //IPA
      "56779bc7-52a4-4b26-a1f3-6f6560d028ec", //FPA
      "ecdf3e3b-c78f-4de4-9f6e-f9d798db4ddd"
    ]
  },
  IGNORE_OFFICE_GUIDS: [
    "fab65ff9-cfc0-4401-8dc5-197fc9e608f0"
  ],
  TBDB_IDS: {
    [SEARCH_SUBJECT]: "Subjects",
    [SEARCH_FPA_IPA_INTERNAL]: "TypeAheadFPA-IPA-Internal",

    [FPA]: "FunctionalPracticeArea",
    [IPA]: "IndustryPracticeArea0",
    [BCG_INTERNAL]: "BCGInternal",
  },
  SUGGESTIONS_TYPE_MAP: {
    INDUSTRYPRACTICEAREA: "IPA",
    FUNCTIONALPRACTICEAREA: "FPA",
    BCGINTERNAL: "BCG_INTERNAL",
    S: "SUBJECT"
  },
  PA: {
    [IPA]: "INDUSTRYPRACTICEAREA",
    [FPA]: "FUNCTIONALPRACTICEAREA",
    [BCG_INTERNAL]: "BCGINTERNAL",
    MODAL_WIDTH: '762px'
  },
  MAILTO_BODY_BROWSER_RESTRICTED_CHARLIMIT: 500,
  KNOWLEDGE_BUNDLE_LIMITS: {
    KBPAGE_MIN_TITLE_CHARLIMIT: 15,
    SUPPORTING_LINKS_MAXLIMIT: 25,
    KNOWLEDGE_ITEMS_MINLIMIT: 2,
    MULTIPLE_SECTOR_TOPICS_MAXLIMIT: 6,
    KNOWLEDGE_ITEMS_MAXLIMIT: 40,
    KNOWLEDGE_ITEMS_INITIAL_DISPLAY: 10,
    DRAGGABLE_KNOWLEDGE_ITEM_HEIGHT: 92,

    MULTIPLE_TOPIC_PAGES_MAXLIMIT: 10,
    HELPMODAL_AUTOCLOSE_TIME_LIMIT: 30, //time in seconds
    BUNDLE_TITLE_CHARLIMIT: 200,
    BUNDLE_RICHEDITOR_CHARLIMIT: 3000
  },
  KP_LIMITS: {
    KPAGE_TITLE_CHARLIMIT: 200,
    KPAGE_MIN_TITLE_CHARLIMIT: 15,
    KPAGE_RICHEDITOR_CHARLIMIT: 3000,
    MULTIPLE_TOPIC_PAGES_MAXLIMIT: 6,

    SEARCH_TAGS_MAXLIMIT: 100, // In lieu of no limit
    ADDITIONAL_URLS_MAXLIMIT: 10,
    KP_URLS_MAXLIMIT: 1,
    KPAGE_MIN_DESCRIPTION_CHARLIMIT: 100,
    SUBJECT_TAGS_MAXLIMIT: 3
  },
  KB_KEYS_TO_REMOVE: [
    'originalPublishDate',
    'createdDate',
    'statusChangeDate',
    'updatedDate',
    'publishDate',
    'lockedBy',
    'version'
  ],
  CP_LIMITS: {
    RELATED_CASES_TITLE: 80, // 80 CHARCTERS - 2 lines
    RELATED_CASES_DESC: 130, // 130 CHARCTERS - 3 lines     
    RELATED_CASES_TEAM: 4, // 4 Team Members        
    RELATED_CASES_SLIDES: 3,
    DESCRIPTION_HEIGHT: 676,
    ASSETS_LIMIT: 5,
  },
  TIMEOUT_LIMITS: {
    LIMIT_NOTIFICATION_SLIDER: 5000,
    LIMIT_SEARCH_KNOWLEDGE_API: 4500,
    DEBOUNCE_LIMIT: 500,
    DELAY_TIME: 1000
  },
  TOPBAR_MESSAGE_UNPUBLISHED: (type) => { return `This is an unpublished ${type}` },
  TOPBAR_MESSAGE_PUBLISHED_PA: (type) => { return `Do you wish to PA recommend/unrecommend this ${type}?` },
  TOPBAR_MESSAGE_PUBLISHED_PA_MODAL: (type) => { return `Which PA/Topic do you recommend/unrecommend this ${type}?` },
  WORKFLOW_BAR_STATUS: (activeStatus) =>
    activeStatus ? ["published"].includes(activeStatus.toLowerCase()) : false,
  TPKB_WORKFLOW_BAR: (step2 = false, step3 = false) => [
    { text: LABELS.WORKFLOW_TITLES.TPKB.STEP1_TITLE, active: true },
    { text: LABELS.WORKFLOW_TITLES.TPKB.STEP2_TITLE, active: step2 },
    { text: LABELS.WORKFLOW_TITLES.TPKB.STEP3_TITLE, active: step3 },
  ],
  KP_WORKFLOW_BAR: (step2 = false, step3 = false) => [
    { text: LABELS.WORKFLOW_TITLES.KP.STEP1_TITLE, active: true },
    { text: LABELS.WORKFLOW_TITLES.KP.STEP2_TITLE, active: step2 },
    { text: LABELS.WORKFLOW_TITLES.KP.STEP3_TITLE, active: step3 },
  ],
  MAIL_TO_LABEL: (label) =>
    label ? ["subject", "ipa", "fpa"].includes(label.toLowerCase()) : false,
  MAIL_TO: (mode = true) => ({
    text: mode
      ? "Have questions related to Taxonomy"
      : "Can’t find the right Search and Discovery tag?",
    buttonText: "Contact us",
    email: "TaxonomyDevSupport@bcg.com",
    subject: "Request for new taxonomy term addition",
  }),
  CONSUMPTION_MAIL: {
    LABELTEXT: "Knowledge Help",
    EMAIL: "KnowledgeHelp@bcg.com",
    SUBJECT: "Access to Restricted Material",
    BODY: "Please provide me the restricted materials for this record:"
  },
  USABILLA: {
    BUTTON_ID: "d0d0c7d779ff",
    MAPPING_SURVERY: {
      KP_PUBLISH: "a28c512331f4",
      CONSUMPTION_RATING: "bfa185fc00c0",
      TC_GRID: "a221774e83a6",
      facecard_download: "a8e4487a0271",
      ai_suggestions: "a980c49e9fce"

    },
    GET_USABILLA_NAME: (buttonId, analyticId) => `usbl.${buttonId}.c.${analyticId}`,
  },
  EMPTY_IMAGE_RESPONSE_SIZE: 4067,
  DISPLAY_TEXT: {
    DEFAULT_ERROR: {
      TITLE: 'Oops, something went wrong!',
      MESSAGE: 'Something went wrong, please try again.'
    },
    ERROR_MESSAGES: {
      AUTH_FAILED: 'Authentication failed.',
      NOT_FOUND: 'The server could not find the data',
      FORBIDDEN: 'You do not have permissions to view this content',
      UNKNOWN_CODE: 'The server returned an unknown HTTP error code.',
      SERVER_ERROR: 'There was an external server error',
      NETWORK_ERROR: 'There was a network error.',
      REQUEST_CANCELLED: 'The request was cancelled.',
    }
  },
  KNOWLEDGE_CONTRIBUTE_VERSION: process.env.REACT_APP_VERSION,
  KNOWLEDGE_STATUS: {
    ALL: "",
    DRAFT: "Draft",
    PUBLISHED: "Published",
    ARCHIVED: "Archived",
    UNPUBLISHED: "Withdraw",
    WITHDRAW: "Withdraw",
    INQUEUE: "In Queue",
    PA_RECOMMDS: "PA Recommendations",
    NO_RESULTS: "No Results",
    REVIEW: "Review"
  },

  KNOWLEDGE_STATUS_MAP: {
    Draft: "Draft",
    Published: "Published",
    Withdraw: "Unpublished",
    Archived: "Archived",
    InQueue: "In Queue"
  },

  KNOWLEDGE_TYPE_KEYS: {
    ALL: "",
    BUNDLES: "kb",
    COLLECTIONS: "tc",
    PAGES: "kp"
  },
  KNOWLEDGE_SORT_KEYS: {
    DATE_ASC: "updatedDate.asc",
    DATE_DESC: "updatedDate.desc"
  },
  KNOWLEDGE_BUNDLE_STATUS: {
    PUBLISHED: "published",
  },
  CREATION_MODAL_OPTION: {
    FILE: "file",
    URL: "url"
  },
  BLACK_LISTED_LINKS: ["navigator.bcg.com", "knowledgecontribution"],
  WHITE_LISTED_LINKS: ["bcgnavigator.bcg.com/video/brightcove", "widen.net"],
  KB_WHITE_LISTED_LINKS: ["bcgnavigator.bcg.com/video/brightcove", "widen.net", "navigator.bcg.com/kb", "practiceareas.navigator.bcg.com"],
  REGEX: {
    // eslint-disable-next-line
    SUPP_LINKS_URL: new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w-.%_~:\/?#[\]@!$&'()*+,;=.]+$/),
    SEARCH_FILTER: new RegExp(/[^a-zA-Z0-9\s&-@,:]/gi),
    CASE_NUMBER: new RegExp(/^\d{5}[0-9]?-?\d{0,3}$/),
    NEWLINE_REGEX: new RegExp(/\n/g),
    PARAGRAPH_REGEX: new RegExp(/<p>(.*?)<\/p>/g),
  },
  XA: process.env.REACT_APP_X_API_KEY,
  HTTP_CONTENT_TYPES: {
    json: "application/json",
    image: "image/jpeg",
    urlencoded: "application/x-www-form-urlencoded;charset=utf-8"
  },
  ENTITLEMENTS: {
    [KNOWLEDGE_CONTRIBUTIONS]: {
      [PRODUCTID]: 1,
      [VIEW]: 1,
      [CREATE_EDIT_DRAFT]: 2,
      [PREVIEW_DOWNLOAD]: 3,
      [PREVIEW_DOWNLOAD_R]: 4,
      [EDIT_PUBLISHED_CONTENT]: 5,
      [PUBLISH_CONTENT]: 6,
      [SHOW_BUNDLE]: 18,
      [TAG_FRAMEWORK]: 38
    },
    [CASE_PAGES]: {
      [PRODUCTID]: 2,
      [VIEW]: 7,
      [VIEW_CASE_DETAIL]: 8,
      [VIEW_CLIENT_INFO]: 9,
      [VIEW_BUDGET]: 10,
      [EDIT]: 11,
      [EDIT_TOPIC_TAGS]: 12,
      [VIEW_CASE_MATERIALS]: 13
    },
    [PROFILES]: {
      [PRODUCTID]: 4,
      [EDIT_COLLEAGUE_PROFILE]: 21
    },
  },
  applicationName: "Ngen Knowledge Contribution App",
  downloadApplicationName: "Knowledge_Page", //TODO same as application name with @Aditya
  downloadTypeForKP: "Knowledge_Page",
  BEARER: "Bearer ",
  DRAFT_STATUS: "Draft",
  PAGE_NUMBER: 1,
  PAGE_SIZE: 2147483647,
  PEOPLE_API_QUERY_PARAMS: {
    DEFAULT: {
      LIMIT: 500,
      OFFSET: 0,
      REQUEST_DATA: "id businessTitle preferredFirstName picture preferredLastName preferredDisplayName workEmail preferredName alumni hostOfficeLocation { id name city state } assistantIds"
    },
    CASE_CONTACT_SEARCH: {
      LIMIT: 500,
      REQUEST_DATA: "id preferredFirstName preferredLastName alumni"
    },
    AUTHORS_LISTING: {
      REQUEST_DATA: "id preferredFirstName preferredLastName alumni"
    }

  },
  SORT: {
    ASC: "ASC",
    DESC: "DESC"
  },

  DOCVIZ_POOLING_API_DATA: {
    TIME: 15000,
    INITIAL_TIME: 4000,
    ERROR_MSZ: 'Docviz Preview currently not available !',
    CALLBACK_KEYS: [
      "WIP",
      "CMP",
      "ERR",
    ]
  },
  MANDATORY_COMMENT: {
    INPUT_CHARLIMIT_MAX: 200,
    INPUT_CHARLIMIT_MIN: 20,
  },
  CP_CONTRIBUTION_LIMITS: {
    EDITOR_LIMIT: 1,
    TITLE_CHAR_LIMIT: 250,
    CLIENT_DESC_CHAR_LIMIT: 600,
    CLIENT_BU_DESC_CHAR_LIMIT: 600,
    CASE_DESC_LIMIT: 7000,
    KNOWLEDGE_ASSETS_LIMIT: 10000,
    SEARCH_CASE_CHAR_LIMIT: 9,
    SEARCH_CASES_LIMIT: 25,
  },
  CASES_SEARCH_PAGINATION: {
    PAGE_SIZE: 25,
    PAGE_NUMBER: 1,
  },
  MY_CASES: {
    TITLE_MAX_LIMIT: 250,
    BST_PATH: ["ipaTags", "fpaTags", "bcgInternalTags"],
    SUBJECT_PATH: ["subjectTags"],
    TITLE_PATH: ["title"],
    PAGE_SIZE: 50,
    PAGE_NUMBER: 1,
    DAYS_FILTER_DEFAULT: process.env.REACT_APP_MY_CASES_DAYS,
    DEFAULT_SELECTED_DURATION: '30',
  },
  CP_DATE_FORMAT: "YYYY-MM-DD",
  CP_UI_DATE_FORMAT: "MMM DD, YYYY",
  PAST_DATE: "Thu, 01 Jan 1970 00:00:00 GMT",
  CASE_CAPTURE_LINK: (caseNumber) => `https://cmp.bcg.com/case-capture/case/${caseNumber}/`,
  CASE_E_OFFICE_LINK: (caseNumber) => `https://navnet.bcg.com/casecapture/casedata_both.aspx?cn=${caseNumber}`,
  STATUS_CODES: {
    "SUCCESS": "200",
  },
  CP_TAXONOMY_CONFIG: {
    PA: [
      { label: "Industry", key: "IPA" },
      { label: "Functional", key: "FPA" },
      { label: "BCG Internal", key: "BCG_INTERNAL" }
    ],
    SUBJECT: [
      { label: "Subject", key: "SUBJECT" }
    ]
  },
  CP_VIGNETTES: process.env.REACT_APP_CASE_VIGNETTES_ID,
  CASE_TYPE_GUID: process.env.REACT_APP_MASTER_CASE_TYPE_GUID,
  CASE_TYPE_LIST: {
    [FOLLOW_ON]: "Follow on",
    [CONVERTED]: "Converted",
    [RELATED]: "Related",
    [MIGRATED]: "Migrated"
  },
  SENIOR_STAFF_POSITIONS: [
    "Partner & Associate Director",
    "Partner & Director",
    "Managing Director and Partner",
    "MD and Senior Partner",
  ],
  TITLES_ALLOWED_THUMBSUPDOWN_KP: [
    "Managing Director and Senior Partner",
    "Managing Director and Partner",
    "Partner and Director",
    "Partner and Associate Director",
    "Partner"
    // added Analyst for QA Testing
    // "Analyst"
  ],
  TITLES_FOR_MDP_OR_ABOVE: [
    "Managing Director and Senior Partner",
    "Managing Director and Partner",
    "Partner and Director",
    "Partner and Associate Director",
    "Partner"
  ],
  RELATED_CASE_LIST: {
    "shortDescription": "shortDescription",
    "description": "description",
    "caseNumber": "caseNumber",
    "caseOpenDate": "caseOpenDate",
    "projectId": "projectId",
    "allCaseTeams": "allCaseTeams",
    "caseType": "caseType",
  },

  SUPPORTED_FILE_TYPES: [
    "pptx", "ppt", "ppsx", "pptm", "ppsm", "ppm", "pps", "pptm", "ppam",
    "pdf", "docx", "doc", "docx", "docxml", "docm",
    "xlsx", "xls", "xlw", "xlt", "xlsm", "xltx", "xltm", "xlwx", "xlsb", "csv",
    "htm", "html", "xhtml", "xhtm", "txt", "text", "jp2", "jpeg", "jpg", "jpx",
    "png", "tif", "tiff"
  ],

  SUPPORTED_FILE_EXTENSIONS: ".pptx, .ppt, .ppsx, .pptm, .ppsm, .ppm, .pps, .pptm, .ppam, .pdf, .docx, .doc, .docx, .docxml, .docm, .xlsx, .xls, .xlw, .xlt, .xlsm, .xltx, .xltm, .xlwx, .xlsb, .csv, .htm, .html, .xhtml, .xhtm, .txt, .text, .jp2, .jpeg, .jpg, .jpx, .png, .tif, .tiff",

  NUDGE_TIMEOUT_LIMITS: {
    DELAY_TIME: 3000
  },
  SUGGESTIONS_TYPE: {
    [SUGGESTIONS_TEXONOMY]: "bst",
    [SUGGESTIONS_AUTHORS]: "authors"
  },
  FIELD_MAPPING_KP: {
    "url": "URL",
    "urltitle": "URL Name",
    "title": "Title",
    "description": "Description",
    "cases": "Related Case",
    "contenttype": "Knowledge Page Type",
    "ipa": "Business Tags",
    "fpa": "Business Tags",
    "bcginternal": "Business Tags",
    "subjects": "Search and Discovery Tags",
    "mdps": "Billing Partner/MDP associated with the case",
    "authors": "Authors",
    "additionalcontacts": "Additional Contacts",
    "relatedtocmp": "Related To CMP",
    "restrictedtomdp": "Restricted To MDP",
    "relatedtogtm": "Related To GTM",
    "internaloffice": "BCG Office",
    "bcgoffice": "BCG Office",
    "language": "Language",
    "additionalurl": "Additional Url",
    "filename": "File Name",
    "custodian": "Custodian",
    "frameworkslides": "Framework Slides",
    "regioncountry": "Impacted Region/Country",
    "filereplaced": "File Replaced",
    "ismdpapprovalrequired": "MDP Approval" //added by pallabi
  },
  S3_BUCKET_NAME: process.env.REACT_APP_UPLOAD_AWS_S3_BUCKET,
  REMOVE_TAGS_REGX: /(<([^>]+)>)/gi,
  TITLES_REQUIRED_FOR_MDP_APPROVAL: [
    ASSOCIATE,
    CONSULTANT,
    PROJECT_LEADER,
    PRINCIPAL,
    PARTNER
  ],
  DOWNLOAD_APPLICATION_NAME: {
    KNOWLEDGE_PAGE_APP_NAME: 'Knowledge_Page',
    KNOWLEDGE_BUNDLE_APP_NAME: 'Knowledge_Bundle',
    CASE_PAGE_APP_NAME: 'Case_Page'
  },
  PROPOSAL_TEMPLATES: [
    // { id: "proposalbiow", theme: "white", label: "Template 1" },
    // { id: "proposalbiog", theme: "green", label: "Template 2" }
  ],
  ONE_SLIDER: { id: "1-slider", label: "1-Slider" },

  KP_TYPE_SORTING_KEY_ORDER: {
    "Case Examples": 5,
    "Case Vignettes": 4,
    "Credentials": 3,
    "Proposals": 2,
    "Selling Materials": 1
  },
  SHARED_CONFIG: {
    GN_CO: {
      SP_XA: process.env.REACT_APP_STAFF_PHOTO_API_KEY,
      PP_XA: process.env.REACT_APP_PRIVACY_POLICY_API_KEY,
      SC_XA: process.env.REACT_APP_SEARCH_CONFIG_API_KEY,
    },
    ST_CO: {
      MAT_XA: process.env.REACT_APP_MATERIALS_API_KEY,
      CA_XA: process.env.REACT_APP_CASES_API_KEY,
      DV1_XA: process.env.REACT_APP_DIRECTORY_API_KEY,
      DV2_XA: process.env.REACT_APP_DIRECTORY_V2_API_KEY,
      BI_XA: process.env.REACT_APP_BCG_INTERNAL_API_KEY
    }
  },
  KEY_KB_TC_DEFAULT_VIEW: 'kb_tc_default_view',
  AUTO_TAG_TYPE_CONFIG_OBJ: (PAGE_TYPE, TAG_TYPE) => ({ tagName: TAG_TYPE, tagToolTipMsg: AUTO_TAG_TYPE_TOOLTIP_CONFIG[PAGE_TYPE][TAG_TYPE] })
};

export default CONFIG;


